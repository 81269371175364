.item_attendance{
    font-size:12px;
    font-weight:bold;
}
.td_border{
    border:1px dotted;
    text-align: center;;
  }
  ion-item {
    --min-height: 16px;
  }
  .ion_item_head{
    --background: #eb445a;
    color: white;
  }
.mn{margin:0;padding:0}
.ion_item_card{
    width:100%;padding:0;
}
.ion_item_card_header{
    padding:5px;
}
.ion_item_card_content{
    padding:0;
    min-height:80vh;
}