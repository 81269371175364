.header{
    background: linear-gradient(to bottom right, #E92954,#b6072d);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    position: sticky;
    top: 0;
    z-index: 9999;
}
/* .header{margin-top: 15px;} */
.header_title{font-weight: bold;font-size: 16px;color: #fff;}
.header_cmp_title{font-size: 12px;color: #fff;}
.header_right_content{
    text-align: right;
}
.header_icons_right{
    text-align: right;
    margin-right: 5px;
    color: #fff;
}
.badge{
    position: absolute;
    top: -10px;
    left: 10px;
}
.header_toolbar{
    border-radius: 15px;
    --background: transparent;
    --color: white;
}