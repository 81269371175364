.custom-tab-bar{
    /* background-color: #000000;
    color: #ffffff; */
}
.location_img_parent {
	border: 1px dotted;
	align-items: center;
	display: block;
	text-align: center;
}
.location_img {
	margin: 0 auto;
	opacity: 1;
	max-height: 160px;
}
.custom-tab-bar {
    contain: initial;
}
.tab-button {
    line-height: 1.5;
    position: relative;
    top: -15px;
    height: 85px;
    justify-content: flex-end;
    background: transparent;
}
.tab-button.tab-selected .tab_icon_holder {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    border: 6px solid #cdcdcd;
    background-image: linear-gradient(to bottom right, #E92954,#b6072d);
    border-radius: 50%;
}
.login_sliders{
    background-color: #b6072d;
}
ion-slides {
    height: 100px;
  }
.tab-button.tab-selected .tab_label{
    background: linear-gradient(to bottom right, #E92954,#b6072d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
}
.tab_icon_holder > * {
    font-size: 1.1rem;
}
.tab-button.tab-selected .tab_icon_holder > * {
    color: #fff;
}
.active_div_tab{

}