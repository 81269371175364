.support_card{
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items:center;
}
.support_title{
    margin: 0;
    padding: 0;
    color:  #FFF;
    font-size: 18px !important;
}
ion-card {
    border-radius: 15px !important;
    --color:#fff;
    --background:linear-gradient(to bottom right, #E92954,#b6072d);
  }